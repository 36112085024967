.compact-item-vertical-border-radius(@prefix-cls) {
  &-item:not(&-first-item):not(&-last-item) {
    border-radius: 0;
  }

  &-item&-first-item {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  &-item&-last-item {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.compact-item-vertical-border(@prefix-cls) {
  // border collapse
  &-item:not(&-last-item) {
    margin-bottom: -@border-width-base;
  }

  &-item {
    &:hover,
    &:focus,
    &:active {
      z-index: 2;
    }

    &[disabled] {
      z-index: 0;
    }
  }
}

.compact-item-vertical(@prefix-cls) {
  &-compact-vertical {
    .compact-item-vertical-border(@prefix-cls);
    .compact-item-vertical-border-radius(@prefix-cls);
  }
}
